import React from "react";
import serviceS1 from "@/images/cyber.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Siguria Kibernetike</h2>
      <p>
        Në Solxk ne jemi të përkushtuar për të ofruar siguri kibernetike të
        klasit botëror Zgjidhje për të mbrojtur asetet tuaja dixhitale Ekipi ynë
        i ekspertëve përdor mjete dhe teknologji lider në industri për të
        identifikuar Zbutni dobësitë, rreziqet dhe siguroni sistemet tuaja Ne i
        identifikojmë ato rëndësia kritike e mbajtjes së aseteve tuaja dixhitale
        para jush aftësi në zhvillim të vazhdueshëm për të mbrojtur peizazhin
        kibernetik Kërcënimet. Zgjidhjet tona gjithëpërfshirëse të sigurisë
        kibernetike janë të përpikta projektuar për të mbështetur bizneset e të
        gjitha madhësive në zbutjen Rreziqet, mbrojtja e të dhënave të ndjeshme
        dhe ruajtja e rregullores Pajtohuni me një mjedis kërcënimi gjithnjë e
        më kompleks.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planifikimi dhe Strategjia</h3>
          <p>Shërbimet që ne ofrojmë:</p>
          <ul>
            <li>
              {/* <i className="fa fa-check-square"></i>Maltego: A powerful tool for
              link analysis,
            </li> */}
              <i className="fa fa-check-square"></i>Penetrationstests
            </li>
            {/* <li>
            <i className="fa fa-check-square"></i>Vulnerability Assessment
            </li> */}
            {/* <i className="fa fa-check-square"></i>Burp Suite:Allows us to
              identify weaknesses
            </li> */}
            {/* <li>
              <i className="fa fa-check-square"></i>Nmap:discover hosts and
              services
            </li> */}
            <li>
              <i className="fa fa-check-square"></i>Kodi i burimit të aplikimit
              rishikime
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>Metasploit: find and fix
              security gaps proactively.
            </li> */}
            <li>
              <i className="fa fa-check-square"></i>Compliance-basierte
              Offensive Anforderungen
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>GVM (OpenVAS): perform
              comprehensive scans...
            </li> */}
            <li>
              <i className="fa fa-check-square"></i> OSINT MALTEGO -Integrim
              trajnim MIT
            </li>
            <li>
              <i className="fa fa-check-square"></i>Zgjidhje të SIEM
              -Mpletacione
            </li>
            <li>
              <i className="fa fa-check-square"></i>Integrimet e Zgjidhjeve të
              WAF
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>Threat Hunting
            </li> */}
            {/* <li>
              <i className="fa fa-check-square"></i>CyberSecurity Training
            </li> */}
          </ul>
        </div>
      </div>
      <p>
        Pavarësisht nëse jeni një startup, keni një identitet të fortë marke set
        ose një kompani e themeluar që synon tuaj Për të përditësuar praninë
        tuaj vizuale, Solxk është këtu për t'ju ndihmuar për të ndihmuar. Na
        kontaktoni sot për të diskutuar dizajnin tuaj nevojave dhe hidhni hapin
        e parë për të transformuar markën tuaj në një transformuar kryevepër
        vizuale.
      </p>
      <p>
        Në Solxk ne e pranojmë se themeli i një suksesi Projekti i zhvillimit
        të Web-it qëndron në planifikimin e përpiktë.Përpara Shkrimi i një
        rreshti të vetëm kodi ose dizajnimi i një piksel të vetëm ne investojmë
        kohë për të kuptuar qëllimet e biznesit tuaj, Grupet e synuara dhe
        kërkesat tona gjithëpërfshirëse Procesi i planifikimit siguron që
        aspekti i projektit tuaj të trajtohet me kujdes merren parasysh dhe
        ekzekutohen me saktësi.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
